/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-plusplus */
import { navigate } from 'gatsby';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Heading from '@components/heading';
import CheckBox from '@components/checkbox';
import TextInput from '@components/textInput';
import Button from '@components/button';
import Dropdown from '@components/dropdown';
import { toTitleCase } from 'utils/stringUtils';
import brandInfo from 'utils/campaignUtils';
import { filterObject } from './filter';

import '@scss/_campaign-filter.scss';

export default function CampaignFilter(props) {
  const { typeFilterData } = filterObject;
  const {
    dropdownProps,
    handleMobileFilter,
    type,
    clearSelections,
    handleBrandSelection,
    brandsName,
    checkedBrands,
    allBrandsSelection,
    minMax,
    setMinMax,
    location,
  } = props;

  const [typeFilter, setTypeFilter] = useState(type);
  const [min, setMin] = useState('');
  const [max, setMax] = useState('');

  useEffect(() => {
    if (minMax) {
      setMin(minMax?.min);
      setMax(minMax?.max);
    }
  }, []);

  useEffect(() => {
    window.history.scrollRestoration = 'manual';
  }, []);

  function handleClearSelections() {
    setTypeFilter('all');
    clearSelections();
    if (handleMobileFilter) {
      handleMobileFilter();
    }
  }

  function handleApplyButton() {
    const minValue = Number(min) || 0;
    const maxValue = max === '' ? null : Number(max);
    props.handleFilterData({ brandIds: checkedBrands, minMax: { min: minValue, max: maxValue } }, typeFilter);
    if (handleMobileFilter) {
      handleMobileFilter();
    }

    if (checkedBrands.length === 1) {
      const selectedBrand = brandInfo?.find((brand) => brand.brandId === checkedBrands[0]);
      navigate(`/kampanyalar?brand=${selectedBrand?.brandName}`);
    } else {
      const hasParams = location?.search;

      if (hasParams !== '') {
        navigate(`/kampanyalar`);
      }
    }
  }
  function handleMaxValue(value) {
    if (value > 0 || value === '') {
      setMax(value);
    }
  }

  function handleMinValue(value) {
    if (value > 0 || value === '') {
      setMin(value);
    }
  }

  return (
    <div className="campaign-filter">
      <Heading type="h3" className="campaign-filter-title">
        Filtreler
        {/* {translate('campaigns.filters.filterTitle')} */}
      </Heading>
      <div className="campaign-filter-wrapper">
        {/* for mobile */}
        <div className="campaign-filter-box campaign-filter-box--mobile">
          <Heading type="h6" className="campaign-filter-box-title">
            Sıralama Seçenekleri
            {/* {translate('campaigns.sortPickerTitle')} */}
          </Heading>
          <Dropdown {...dropdownProps} />
        </div>
        {/* filter type */}
        {/* <div className="campaign-filter-box">
          <Heading type="h6" className="campaign-filter-box-title">
            {translate('campaigns.filters.filterBoxCampaignsTitle')}
          </Heading>
          <div className="campaign-filter-box-button-wrapper">
            {typeFilterData.map(item => (
              <Button
                className={typeFilter === item.value ? 'selected' : ''}
                onPress={() => setTypeFilter(item.value)}
              >
                {item.text}
              </Button>
            ))}
          </div>
        </div> */}
        {/* filter min-max */}
        <div className="campaign-filter-box">
          <Heading type="h6" className="campaign-filter-box-title">
            Fiyat
            {/* {translate('campaigns.filters.filterBoxPriceTitle')} */}
          </Heading>
          <div className="campaign-filter-box-input-wrapper">
            <TextInput
              placeholder="Min"
              // placeholder={translate('campaigns.filters.filterPrice.min')}
              min="0"
              onChangeText={(value) => handleMinValue(value)}
              value={min}
            />
            <TextInput
              placeholder="Max"
              // placeholder={translate('campaigns.filters.filterPrice.max', {})}
              min="0"
              onChangeText={(value) => handleMaxValue(value)}
              value={max}
            />
          </div>
        </div>
        {/* filter restaurants */}
        <div className="campaign-filter-box">
          <Heading type="h6" className="campaign-filter-box-title">
            Restoran Seçimi
            {/* {translate('campaigns.filters.filterBoxRestaurantChooseTitle')} */}
          </Heading>
          <div onClick={() => allBrandsSelection()} className="campaign-filter-box-restaurant">
            <div className="campaign-filter-box-restaurant-name">
              Tüm Restoranlar
              {/* {translate('campaigns.filters.allRestaurants')} */}
            </div>
            <CheckBox
              onPress={() => allBrandsSelection()}
              rounded
              checked={checkedBrands?.length === brandsName?.length}
            />
          </div>
          {brandsName?.map((brand, index) => (
            <div className="campaign-filter-box-restaurant" key={`campaign-filter-box-restaurant-${index + 1}`}>
              <div
                className="campaign-filter-box-restaurant-name"
                onClick={() => handleBrandSelection(brand?.node?.CRMId)}
              >
                <img
                  src={brand?.node?.Logo?.publicURL}
                  alt={brand?.node?.Name}
                  width="32"
                  height="32"
                  className="campaign-filter-box-restaurant-name-icon"
                />
                {/* {brand?.node?.Name} */}
                {toTitleCase(brand?.node?.Name)}
              </div>
              <CheckBox
                onPress={() => handleBrandSelection(brand?.node?.CRMId)}
                rounded
                checked={checkedBrands.includes(brand?.node?.CRMId)}
                value={brand?.node?.NormalizedName}
              />
            </div>
          ))}
        </div>
        {/* for mobile clear-apply buttons */}
        <div className="campaign-filter-box campaign-filter-box--mobile">
          <div className="campaign-filter-box-input-wrapper">
            <Button
              className="campaign-filter-button-mobile btn-outline-danger"
              onPress={() => handleClearSelections()}
            >
              Seçimleri Sıfırla
              {/* {translate('campaigns.filters.clearSelectionButtonValue')} */}
            </Button>
            <Button className="campaign-filter-button-mobile" onPress={() => handleApplyButton()}>
              Uygula
              {/* {translate('campaigns.filters.applyButton')} */}
            </Button>
          </div>
        </div>
      </div>
      <Button className="campaign-filter-apply-btn" onPress={() => handleApplyButton()}>
        Uygula
        {/* {translate('campaigns.filters.applyButton')} */}
      </Button>
    </div>
  );
}

CampaignFilter.propTypes = {
  handleFilterData: PropTypes.func,
  dropdownProps: PropTypes.object,
  type: PropTypes.string,
  setType: PropTypes.func,
  handleMobileFilter: PropTypes.bool,
  clearSelections: PropTypes.func,
  handleBrandSelection: PropTypes.func,
  brandsName: PropTypes.array,
  checkedBrands: PropTypes.array,
  allBrandsSelection: PropTypes.func,
  minMax: PropTypes.object,
  setMinMax: PropTypes.func,
  location: PropTypes.object,
};
