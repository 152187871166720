/**
 *
 * TextInput
 *
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

import { regexEmoji } from 'utils/regex';
import '@scss/_text-input.scss';

function removeEmojis(string) {
  return string.replace(regexEmoji, '');
}

function TextInput({
  id,
  name,
  label,
  type,
  placeholder,
  value,
  defaultValue,
  error,
  onChangeText,
  style,
  inputProps,
  secureText,
  onBlur,
  disabled,
  className,
}) {
  function onChange(e) {
    onChangeText(removeEmojis(e.target.value));
  }

  return (
    <Form.Group controlId={id}>
      {label && <div className="form-label">{label}</div>}
      <Form.Control
        type={secureText ? 'password' : type || 'text'}
        name={name}
        placeholder={placeholder}
        value={value}
        defaultValue={defaultValue}
        onChange={(e) => onChange(e)}
        style={style}
        disabled={disabled}
        className={className}
        onBlur={() => {
          // eslint-disable-next-line no-unused-expressions
          onBlur && onBlur();
        }}
        {...inputProps}
      />
      {error && <Form.Text className="text-danger">{error}</Form.Text>}
    </Form.Group>
  );
}

TextInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onChangeText: PropTypes.func,
  inputProps: PropTypes.object,
  style: PropTypes.object,
  secureText: PropTypes.bool,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export default memo(TextInput);
