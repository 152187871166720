export const filterObject = {
  typeFilterData: [
    {
      value: 'special',
      text: 'Bana Özel',
    },
    {
      value: 'all',
      text: 'Genel',
    },
  ],
};
