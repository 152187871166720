/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-useless-escape */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import Heading from '@components/heading';
import Button from '@components/button';
import CurrencyText from '@components/currencyText';
import { analyticsViewPromotion, analyticsSelectPromotion } from 'utils/analyticsUtils';
import Link from '../Link';
import '@scss/_campaign-card.scss';

function CampaignCard(props) {
  const { title, image, endDate, descriptionList, menuOptions, details, slug } = props.item.campaign;
  const [defaultMenuOptions, setDefaultMenuOptions] = useState([]);
  const [isShowed, setIsShowed] = useState(false);
  const cartItemRef = useRef();

  useEffect(() => {
    setDefaultMenuOptions(menuOptions?.filter((item) => item.default));
  }, []);

  useEffect(() => {
    const isShow =
      cartItemRef.current?.getBoundingClientRect().bottom > 0 &&
      cartItemRef.current?.getBoundingClientRect().top < window.innerHeight;

    if (!isShowed && isShow) {
      setIsShowed(true);

      analyticsViewPromotion({
        currentItem: props.item.campaign,
        page: 'KAMPANYALAR',
      });
    }
  }, [props.pageYOffset]);

  function dateDiff() {
    const dateNow = dayjs(Date.now());
    const dateEnd = dayjs(endDate);
    const diffDate = dateEnd?.diff(dateNow, 'day');
    return diffDate;
  }

  function discountValue() {
    const price = details?.price;
    if (price) {
      const discountPrice = details?.discountPrice;
      const percentDiff = ((price - discountPrice) / price) * 100;
      return percentDiff?.toFixed(0);
    }
    return null;
  }
  return (
    <>
      <div
        className="campaign-card"
        ref={cartItemRef}
        onClick={() => {
          analyticsSelectPromotion({ item: props.item.campaign, page: 'KAMPANYALAR' });
        }}
      >
        <Link to={`/kampanyalar/${slug}`} className="campaign-card-link" role="button">
          <div className="campaign-card-cover">
            <img src={image?.url} alt={title} className="campaign-card-cover-image" width="100%" height="auto" />
          </div>
          <div className="campaign-card-content">
            <div className="campaign-card-content-info">
              <div className="campaign-card-content-info-inner">
                <Heading type="h3" className="campaign-card-title">
                  {title}
                </Heading>

                <div className="campaign-card-price">
                  {!!details?.price && (
                    <div className="campaign-card-price-old">
                      <CurrencyText>{details?.price}</CurrencyText>
                    </div>
                  )}
                  {!!details?.discountPrice && (
                    <div className="campaign-card-price-new">
                      <CurrencyText>{details?.discountPrice}</CurrencyText>
                    </div>
                  )}
                </div>
              </div>
              {defaultMenuOptions?.map((item, index) => (
                <div className="campaign-card-menu-options">
                  <strong key={item?.product?.Name}>
                    {item?.product?.Name}
                    {index + 1 !== defaultMenuOptions?.length ? ' + ' : ''}
                  </strong>
                </div>
              ))}
              {details?.products?.length === 1 && details?.discountPrice !== null && details?.discountPrice !== 0 && (
                <div className="campaign-card-menu-options">
                  {`Sadece `}
                  <strong>
                    <CurrencyText>{details?.discountPrice}</CurrencyText>
                  </strong>
                </div>
              )}
              {descriptionList?.length > 0 && (
                <ul className="campaign-card-description-items">
                  {descriptionList?.map((item, index) => (
                    <li className="campaign-card-description-item" key={`campaign-card-description-item-${index + 1}`}>
                      <i className={`icon-${item?.icon?.Icon}`} /> {item?.text}
                    </li>
                  ))}
                </ul>
              )}
              <ul className="campaign-card-tag-items">
                {discountValue() && (
                  <li className="campaign-card-tag-item campaign-card-tag-item--primary">
                    % {discountValue()} İndirim
                    {/* {translate('campaigns.discountText', {
                      discountValue: discountValue(),
                    })} */}
                  </li>
                )}
                {dateDiff() < 1 ? (
                  <li className="campaign-card-tag-item campaign-card-tag-item--secondary">Son Gün</li>
                ) : (
                  <li className="campaign-card-tag-item campaign-card-tag-item--secondary">
                    Son {dateDiff()} Gün
                    {/* {translate('campaigns.endDateText', {
                    dateDiff: dateDiff(),
                  })} */}
                  </li>
                )}
              </ul>
            </div>
          </div>
        </Link>
        <Link to={`/kampanyalar/${slug}`} className="campaign-card-detail-link" role="button">
          <Button className="campaign-card-detail-link-btn">
            Detayları Gör
            {/* {translate('campaigns.campaignCard.detailButton')} */}
          </Button>
          <Button className="campaign-card-detail-link-btn-mobile" />
        </Link>
      </div>
    </>
  );
}

CampaignCard.propTypes = {
  item: PropTypes.object,
  pageYOffset: PropTypes.number,
};

export default CampaignCard;
