/**
 *
 * NoData
 *
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import '@scss/_no-data.scss';

function NoData(props) {
  return (
    <div className={`no-data ${props.className}`}>
      <i className={props.icon} />
      <div>{props.children}</div>
    </div>
  );
}

NoData.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string, // min-50, min-25
  icon: PropTypes.string,
};

export default memo(NoData);
