/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import { useStaticQuery, graphql } from 'gatsby';
import Heading from '@components/heading';
import Dropdown from '@components/dropdown';
import { Row, Col } from 'react-bootstrap';
import CampaignCard from '@components/CampaignCard/index';
import CampaignFilter from '@components/CampaignFilter/index';
import CampaignLoader from '@components/campaignLoader';
import NoData from '@components/noData';
import { cloneDeep } from 'lodash';
import Layout from '@components/layout';
import SEO from '@components/seo';
import { analyticsCampaignsFilter } from '../utils/analyticsUtils';
import { eventInsider } from '../utils/insiderUtils';

const sortFilterData = [
  {
    label: 'Tarihe göre (Yeniden eskiye)',
    value: 'Tarihe göre (Yeniden eskiye)',
    key: 0,
  },
  {
    label: 'Tarihe göre (Eskiden yeniye)',
    value: 'Tarihe göre (Eskiden yeniye)',
    key: 1,
  },
  {
    label: 'Fiyata göre (Artan)',
    value: 'Fiyata göre (Artan)',
    key: 3,
  },
  {
    label: 'Fiyata göre (Azalan)',
    value: 'Fiyata göre (Azalan)',
    key: 4,
  },
];

export default function CampaignsPage({ location, pageContext }) {
  const [pageYOffset, setPageYOffset] = useState(0);
  const [sortFilterKey, setSortFilterKey] = useState(sortFilterData[0]);
  const [sortType, setSortType] = useState(-1);
  const [mobileFilter, setMobileFilter] = useState();
  const [filterCount, setFilterCount] = useState(0);
  const [checkedBrands, setCheckedBrands] = useState([]);
  const [minMax, setMinMax] = useState();
  const [filterData, setFilterData] = useState(null);
  const [campaignData, setCampaignData] = useState(null);

  const title = 'Kampanyalar & Duyurular - Tıkla Gelsin';
  const description = "Tıkla Gelsin'nin tüm kampanya ve duyurularını hemen bu sayfadan takip edin.";
  const schema = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    url: location.pathname,
    name: title,
    description,
  };

  const brandsName = pageContext.brands.sort((a, b) => (a?.node?.DisplayOrder > b?.node?.DisplayOrder ? 1 : -1));

  useEffect(() => {
    const selectedBrands = [];
    for (let i = 0; i < brandsName?.length; i += 1) {
      selectedBrands.push(brandsName[i]?.node?.CRMId);
    }
    setCheckedBrands(selectedBrands);
    const campaigns = cloneDeep(pageContext?.campaigns);
    setCampaignHandler(campaigns);

    eventInsider('campaign_page_view');
  }, []);

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setPageYOffset(window.pageYOffset);
  };

  useEffect(() => {
    window.history.scrollRestoration = 'manual';
  }, []);

  useEffect(() => {
    if (sortType < 0) {
      return;
    }
    const filteredData = cloneDeep(campaignData || pageContext?.campaigns);
    sortCampaignList(filteredData);
  }, [sortType]);

  useEffect(() => {
    let selectedFilterCount = 0;
    if (sortFilterKey !== sortFilterData[0]) {
      selectedFilterCount += 1;
    }
    if (checkedBrands?.length !== brandsName?.length) {
      selectedFilterCount += 1;
    }
    if (minMax?.min || minMax?.max) selectedFilterCount += 1;
    setFilterCount(selectedFilterCount);
  }, [sortFilterKey, checkedBrands, minMax]);

  useEffect(() => {
    if (filterData) {
      let filteredData = cloneDeep(pageContext?.campaigns);
      const min = filterData?.minMax?.min;
      const max = filterData?.minMax?.max;
      const selectedBrands = filterData?.brandIds;
      if (min) {
        filteredData = filteredData?.filter((fdata) => Number(min) <= Number(fdata?.campaign?.details?.discountPrice));
      }
      if (max) {
        filteredData = filteredData?.filter((fdata) => Number(max) >= Number(fdata?.campaign?.details?.discountPrice));
      }
      let result = [];
      if (selectedBrands?.length > 0) {
        for (let i = 0; i < selectedBrands?.length; i += 1) {
          for (let j = 0; j < filteredData?.length; j += 1) {
            if (filteredData[j].campaign?.details?.acceptableRestaurants[0]?.CRMId === selectedBrands[i]) {
              result.push(filteredData[j]);
            }
          }
        }
      }
      if (result === []) {
        result = filteredData;
      }

      const resultSortCampaignList = sortCampaignList(result);
      setCampaignHandler(resultSortCampaignList, true);
    }
  }, [filterData]);

  function handleBrandSelection(value) {
    const checkedBrandList = [...checkedBrands];
    const index = checkedBrandList?.findIndex((item) => item === value);
    if (index !== -1) {
      checkedBrandList.splice(index, 1);
    } else {
      checkedBrandList.push(value);
    }
    setCheckedBrands(checkedBrandList);
  }

  function clearSelections() {
    setSortType(0);
    setSortFilterKey(sortFilterData[0]);
    setMinMax(null);
    const filterBrands = [];
    for (const i of brandsName) {
      filterBrands.push(i?.node?.CRMId);
    }
    setCheckedBrands(filterBrands);
    handleFilterData({ brandIds: filterBrands }, 'all');
  }

  function allBrandsSelection() {
    const filterBrands = [];
    for (const i of brandsName) {
      filterBrands.push(i?.node?.CRMId);
    }
    setCheckedBrands(filterBrands);
  }

  function handleFilterData(filterValue) {
    setMinMax(filterValue?.minMax);
    setFilterData(filterValue);
    analyticsCampaignsFilter(filterValue);
  }

  function handleSortFilterChanged(option) {
    setSortFilterKey(option);
    setSortType(option.key);
  }

  function handleMobileFilter() {
    setMobileFilter(!mobileFilter);
  }

  function setCampaignHandler(camp, isSortHandle) {
    filterAvBrands(camp, isSortHandle);
  }

  function filterAvBrands(data, isSortHandle) {
    if (!isSortHandle) {
      let myArray = [];
      for (const i of brandsName) {
        const filteredCampaigns = data?.filter(
          ({ campaign }) => i?.node?.CRMId === campaign?.details?.acceptableRestaurants[0]?.CRMId,
        );
        filteredCampaigns.sort((a, b) => (a?.campaign?.rowNumber > b?.campaign?.rowNumber ? 1 : -1));
        if (filteredCampaigns?.length) {
          myArray.push({
            name: filteredCampaigns[0]?.campaign?.details?.acceptableRestaurants[0]?.Name,
            type: 999,
          });
          myArray = [...myArray, ...filteredCampaigns];
        }
      }
      setCampaignData(myArray);
    } else {
      setCampaignData(data);
    }
  }

  function sortCampaignList(campaignList) {
    if (!campaignList) {
      return;
    }
    switch (sortType) {
      case -1:
      case 0:
        campaignList.sort(
          (a, b) => new Date(b?.campaign?.startDate).getTime() - new Date(a?.campaign?.startDate).getTime(),
        );
        break;
      case 1:
        campaignList.sort(
          (a, b) => new Date(a?.campaign?.startDate).getTime() - new Date(b?.campaign?.startDate).getTime(),
        );
        break;
      case 3:
        campaignList.sort((a, b) => {
          const firstPrice = a?.campaign?.details?.discountPrice || a?.campaign?.details?.price || 0;
          const secondPrice = b?.campaign?.details?.discountPrice || b?.campaign?.details?.price || 0;
          return firstPrice - secondPrice;
        });
        break;
      case 4:
        campaignList.sort((a, b) => {
          const firstPrice = b?.campaign?.details?.discountPrice || b?.campaign?.details?.price || 0;
          const secondPrice = a?.campaign?.details?.discountPrice || a?.campaign?.details?.price || 0;
          return firstPrice - secondPrice;
        });
        break;
      default:
        break;
    }
    // eslint-disable-next-line consistent-return
    setCampaignHandler(campaignList, true);
    // eslint-disable-next-line consistent-return
    return campaignList;
  }

  return (
    <Layout sidebar={false} location={location}>
      <SEO
        title={title}
        siteUrl={location?.pathname}
        schemaMarkup={schema}
        description={description}
        deepLinkUrl="campaign/0"
      />
      <div className="campaigns-page">
        <div className="campaigns-header">
          <Row className="align-items-center">
            <Col lg={3} sm={6} xs={6}>
              <Heading className="page-title">Kampanyalar</Heading>
            </Col>
            <Col lg={9} sm={6} xs={6}>
              <div className="campaigns-header-sort">
                <Dropdown
                  disableFilter
                  isSearchable={false}
                  options={sortFilterData}
                  value={sortFilterKey}
                  onChange={(option) => handleSortFilterChanged(option)}
                />
              </div>
              <div
                className={
                  mobileFilter ? 'campaigns-header-filter campaigns-header-filter--opened' : 'campaigns-header-filter'
                }
                onClick={handleMobileFilter}
                role="button"
              >
                Filtreler
                {!!filterCount && <span className="campaigns-header-filter-badge">{filterCount}</span>}
              </div>
            </Col>
            {mobileFilter && (
              <Col xs={12}>
                <CampaignFilter
                  filterData={filterData}
                  handleFilterData={(data) => handleFilterData(data)}
                  dropdownProps={{
                    disableFilter: true,
                    isSearchable: false,
                    options: sortFilterData,
                    value: sortFilterKey,
                    onChange: handleSortFilterChanged,
                  }}
                  handleMobileFilter={() => handleMobileFilter()}
                  clearSelections={() => clearSelections()}
                  handleBrandSelection={(value) => handleBrandSelection(value)}
                  checkedBrands={checkedBrands}
                  allBrandsSelection={() => allBrandsSelection()}
                  minMax={minMax}
                  setMinMax={setMinMax}
                  brandsName={brandsName}
                  location={location}
                />
              </Col>
            )}
          </Row>
        </div>
        <div className="campaigns-content">
          <Row>
            <Col lg={3} sm={12}>
              <div className="campaigns-content-filter">
                <CampaignFilter
                  filterData={filterData}
                  handleFilterData={(data) => handleFilterData(data)}
                  clearSelections={() => clearSelections()}
                  handleBrandSelection={(value) => handleBrandSelection(value)}
                  checkedBrands={checkedBrands}
                  allBrandsSelection={() => allBrandsSelection()}
                  minMax={minMax}
                  setMinMax={setMinMax}
                  brandsName={brandsName}
                  location={location}
                />
              </div>
            </Col>
            <Col lg={9} sm={12}>
              <div className="campaigns-content-right">
                {campaignData?.length > 0 ? (
                  <div>
                    {!campaignData ? (
                      <CampaignLoader viewBox="0 0 700 300" />
                    ) : (
                      campaignData?.map((item) => {
                        if (item?.type === 999) {
                          return (
                            <Heading type="h3" className="campaigns-content-section-title" key={item?.id}>
                              {item?.name}
                            </Heading>
                          );
                        }
                        return <CampaignCard item={item} key={item?.id} pageYOffset={pageYOffset} />;
                      })
                    )}
                  </div>
                ) : (
                  <>
                    <NoData className="campaigns-content-no-data" icon="icon-Alert">
                      Aradığınız kriterlere uygun kampanya bulunamadı
                    </NoData>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Layout>
  );
}

CampaignsPage.propTypes = {
  location: PropTypes.object,
  pageContext: PropTypes.any,
};
